@-webkit-keyframes sinusoid{
    0%,50%{
        -webkit-transform:translateY(0);
        transform:translateY(0);
        -webkit-animation-timing-function:cubic-bezier(.2,.25,.55,1);
        animation-timing-function:cubic-bezier(.2,.25,.55,1)
    }
    25%{
        -webkit-transform:translateY(-7px);
        transform:translateY(-7px);
        -webkit-animation-timing-function:cubic-bezier(.45,0,.8,.75);
        animation-timing-function:cubic-bezier(.45,0,.8,.75)
    }
    75%{
        -webkit-transform:translateY(7px);
        transform:translateY(7px);
        -webkit-animation-timing-function:cubic-bezier(.45,0,.8,.75);
        animation-timing-function:cubic-bezier(.45,0,.8,.75)
    }
    to{
        -webkit-transform:translateY(0);
        transform:translateY(0)
    }
}
@keyframes sinusoid{
    0%,50%{
        -webkit-transform:translateY(0);
        transform:translateY(0);
        -webkit-animation-timing-function:cubic-bezier(.2,.25,.55,1);
        animation-timing-function:cubic-bezier(.2,.25,.55,1)
    }
    25%{
        -webkit-transform:translateY(-7px);
        transform:translateY(-7px);
        -webkit-animation-timing-function:cubic-bezier(.45,0,.8,.75);
        animation-timing-function:cubic-bezier(.45,0,.8,.75)
    }
    75%{
        -webkit-transform:translateY(7px);
        transform:translateY(7px);
        -webkit-animation-timing-function:cubic-bezier(.45,0,.8,.75);
        animation-timing-function:cubic-bezier(.45,0,.8,.75)
    }
    to{
        -webkit-transform:translateY(0);
        transform:translateY(0)
    }
}

//responsive viewport
@function get-vw($target) {
	$vw-context: (1920 * .01) * 1px;
	@return ($target/$vw-context) * 1vw;
}



#float-iphone{
    position: relative;
    display: flex;
    align-self: center;
    height: get-vw(700px);
}

.ball{
    border-radius: get-vw(35px);
    position: absolute; 
    background-repeat: no-repeat;
    background-size: contain;
}

#ball_1{
    width: get-vw(827px);
    height: get-vw(582px);
    -webkit-animation: sinusoid 4s 0s linear infinite;
    animation: sinusoid 4s 0s linear infinite;
    background-image: url('../images/layer-1.png');
    top: get-vw(80px);
}
#ball_2{
    width: get-vw(692px); 
    height: get-vw(481px);
    -webkit-animation: sinusoid 4s 0.5s linear infinite;
    animation: sinusoid 4s 0.5s linear infinite;
    background-image: url('../images/layer-2.png');
    top: get-vw(80px);
    left: get-vw(70px);
    
}
#ball_3{
    width: get-vw(687px); 
    height: get-vw(479px);
    -webkit-animation: sinusoid 4s 1s linear infinite;
    animation: sinusoid 4s 1s linear infinite;
    background-image: url('../images/layer-3.png');
    left: get-vw(80px);
    top: get-vw(40px);
    
}